import { localize } from './_locale';
import {
    setPaginationCallback,
    setPagination,
    resetPagination,
    paginationGetCurrentPage,
} from './_pagination';

function reloadProducts(params) {
    let query = [`page=${paginationGetCurrentPage()}`].concat(params);
    fetch('?json=true&' + query.join('&')).then((res) => {
        return res.json();
    }).then(data => {
        let html = '';
        if (data.list.length == 0) {
            html += `<div><h4>${localize("products.notice.nothingFound")}</h4></div>`;
        } else {
            html += '<div class="item-list --grid">';
            data.list.forEach((p) => {
                html += '<div class="item">';
                html += '<div class="product-item">';
                html += `<div class="image" style="background-image: url(${p.image});"></div>`;
                html += '<div class="info">';
                html += `<h6>${p.name}</h6>`;
                html += `<p>${p.description}</p>`;
                html += `<a class="btn outlined --small" href="${p.url}">${localize("products.btn.viewDetails")}</a>`;
                html += '</div>';
                html += '</div>';
                html += '</div>';
            });
            html += '</div>';
        }
        document.querySelector('.product-list-view-products .data').innerHTML = html;
        document.querySelector('.product-list-view').classList.remove('--loading');
        setPagination(data.page, data.pageCount);
    });
}

function reloadDocuments(params) {
    let query = [`page=${paginationGetCurrentPage()}`].concat(params);
    fetch('?json=true&' + query.join('&')).then((res) => {
        return res.json();
    }).then(data => {
        let html = '';
        if (data.list.length == 0) {
            html += `<div><h4>${localize("search.noResults")}</h4></div>`;
        } else {
            html += '<table>';
            html += '<thead>';
            html += '<tr>';
            html += `<th>${localize("generic.documentType")}</th>`;
            html += `<th>${localize("generic.documentName")}</th>`;
            html += `<th>${localize("generic.size")}</th>`;
            html += `<th>${localize("generic.format")}</th>`;
            html += '</tr>';
            html += '</thead>';
            html += '<tbody>';
            data.list.forEach((doc) => {
                html += '<tr>';
                html += `<td>${localize(doc.typeLocaleKey)}</td>`;
                html += `<td class="green"><a href="${doc.url}">${doc.name}</a></td>`;
                html += `<td>${doc.size}</td>`;
                html += `<td>${doc.format.toUpperCase()}</td>`;
                html += '</tr>';
            });
            html += '</tbody>';
            html += '</table>';
        }
        document.querySelector('.document-list-view-documents .data').innerHTML = html;
        document.querySelector('.document-list-view').classList.remove('--loading');
        setPagination(data.page, data.pageCount);
    });
}

function setDomClass(rootDom, selector, cls, disabled) {
    const dom = rootDom.querySelector(selector);
    if (dom) {
        if (disabled) {
            dom.classList.remove(cls);
        } else {
            dom.classList.add(cls);
        }
    }
}

function filterBoxOnInit() {
    const searchQueryForm = document.getElementById('query-form');
    const searchQueryDom = document.getElementById('search_query');
    const clearFiltersBtn = document.getElementById('btn-clear-filters');

    if (!(searchQueryForm && searchQueryDom)) {
        return;
    }

    let reloadTO;
    let filters = null;
    let filterOptions = [];
    function updateFilters() {
        const reload = filters != null;
        let nf = {};
        filterOptions.filter((f) => f.dom.checked).forEach((f) => {
            if (!nf[f.filterId]) {
                nf[f.filterId] = [f.valueId];
            } else {
                nf[f.filterId].push(f.valueId);
            }
        });

        const searchQuery = searchQueryDom.value;
        let url = location.pathname + '?';
        let params = [];
        if (searchQuery) {
            params.push(`query=${encodeURI(searchQuery)}`);
        }
        Object.keys(nf).forEach((filterId) => {
            params.push(`${filterId}=${encodeURI(nf[filterId].join(','))}`);
        });
        if (clearFiltersBtn) {
            if (Object.keys(nf).length) {
                clearFiltersBtn.classList.remove('--disabled');
            } else {
                clearFiltersBtn.classList.add('--disabled');
            }
        }
        if (filters != null) {
            window.history.pushState({}, "", location.pathname + '?' + params.join('&'));
            setDomClass(document, '.product-list-view', '--loading');
            setDomClass(document, '.document-list-view', '--loading');

            clearTimeout(reloadTO);
            if (document.querySelector('.product-list-view-products')) {
                reloadTO = setTimeout(() => { reloadProducts(params); }, 750);
            } else if (document.querySelector('.document-list-view-documents')) {
                reloadTO = setTimeout(() => { reloadDocuments(params); }, 750);
            }
        }
        filters = nf;
    };

    setPaginationCallback(updateFilters);

    searchQueryDom.addEventListener('change', function () {
        resetPagination();
        updateFilters();
    });
    searchQueryForm.addEventListener('submit', function (e) {
        resetPagination();
        updateFilters();
        e.preventDefault();
        e.stopPropagation();
    });

    document.querySelectorAll('.togglable').forEach((dom) => {
        const header = dom.querySelector('h4');
        header.addEventListener('click', () => {
            dom.classList.toggle('--open');
        });
    });


    const filterBoxes = document.querySelectorAll('.filter-box');
    filterBoxes.forEach(function (dom) {
        const filterId = dom.getAttribute('data-id');
        const item = dom.querySelector('.filter-box-item');
        const label = dom.querySelector('.label');

        item.addEventListener('click', function () {
            filterBoxes.forEach(function (filterBoxDom) {
                if (filterBoxDom != dom) {
                    filterBoxDom.classList.remove('--open');
                }
            });
            dom.classList.toggle('--open');
        });

        label.addEventListener('click', () => {
            dom.classList.toggle('--closed');
        });

        const btnShowMore = dom.querySelector('.btn-show-more');
        if (btnShowMore) {
            btnShowMore.addEventListener('click', () => {
                dom.classList.remove('--partial');
                btnShowMore.style.display = 'none';
            });
        }

        const options = item.querySelector('.options');

        const domOptions = options.querySelectorAll('input[type="checkbox"]');

        const update = function () {
            let checkedIds = [];
            domOptions.forEach(function (domCheck) {
                if (domCheck.checked) {
                    checkedIds.push(domCheck.getAttribute('data-id'));
                }
            });
            if (checkedIds.length == domOptions.length || checkedIds.length == 0) {
                dom.classList.remove('--active');
            } else {
                dom.classList.add('--active');
                dom.classList.remove('--closed');
            }
        };

        domOptions.forEach(function (domCheck) {
            filterOptions.push({
                filterId: filterId,
                valueId: domCheck.getAttribute('data-id'),
                dom: domCheck,
                update: update,
            });
            domCheck.addEventListener('click', function () {
                update();
                resetPagination();
                updateFilters();
            });
        });

        update();
    });


    if (clearFiltersBtn) {
        clearFiltersBtn.addEventListener('click', () => {
            filterOptions.forEach((o) => o.dom.checked = false);
            updateFilters();
        });
    }

    if (location.search.length > 0) {
        let queryFilters = {};
        location.search.slice(1).split('&').forEach((q) => {
            const parts = q.split('=', 2);
            if (parts.length != 2) {
                return;
            }
            const val = decodeURI(parts[1]);
            if (parts[0] == 'query' && searchQueryDom) {
                searchQueryDom.value = val;
                return;
            }
            queryFilters[parts[0]] = val.split(',');
        });
        filterOptions.forEach((o) => {
            o.dom.checked = (queryFilters[o.filterId] || []).indexOf(o.valueId) > -1;
            o.update();
        });
    }

    updateFilters();
};

export {
    filterBoxOnInit,
}
