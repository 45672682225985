function formOnInit() {
    document.querySelectorAll('.form-view').forEach((domForm) => {
        const fields = [];
        domForm.querySelectorAll('.field').forEach((dom) => {
            const field = {
                dom: dom,
                required: !!dom.querySelector('.required'),
                input: dom.querySelector('input,textarea'),
                isCheckbox: !!dom.querySelector('.input-check'),
            };
            field.input.addEventListener('change', () => {
                if ((field.isCheckbox && field.checked) ||
                (!!field.input.value.trim())) {
                    field.dom.classList.remove('--mustfill');
                }
            });
            fields.push(field);
        });

        const domRecaptcha = domForm.querySelector('.g-recaptcha');

        function validate() {
            let valid = true;

            if (domRecaptcha) {
                if (domForm.querySelector('.g-recaptcha-response').value == '') {
                    valid = false;
                    domRecaptcha.classList.add('--mustfill');
                } else {
                    domRecaptcha.classList.remove('--mustfill');
                }
            }

            fields.forEach((field) => {
                if (!field.required) {
                    return;
                }
                let ok = true;
                if (field.isCheckbox && !field.input.checked) {
                    ok = false;
                } else if(!field.isCheckbox && !field.input.value.trim()) {
                    ok = false;
                }
                if (ok) {
                    field.dom.classList.remove('--mustfill');
                } else {
                    valid = false;
                    field.dom.classList.add('--mustfill');
                }
            });
            return valid;
        }

        domForm.addEventListener('submit', (e) => {
            if (!validate()) {
                e.preventDefault();
                e.stopPropagation();
            }
        });
    });
}

export {
    formOnInit,
}
