function bigSearchOnInit() {
    document.querySelectorAll('.big-search form').forEach((domForm) => {
        const domInput = domForm.querySelector('input[type="text"]');
        const domSubmit = domForm.querySelector('input[type="submit"]');
        const update = () => {
            if (domInput.value.trim() != '') {
                domSubmit.removeAttribute('disabled');
            } else {
                domSubmit.setAttribute('disabled', 'disabled');
            }
        };

        domInput.addEventListener('keydown', update);
        domInput.addEventListener('change', update);
        domForm.onsubmit = () => domInput.value.trim() !== '';

        update();
    });
};

export {
    bigSearchOnInit,
}
