// Retrieve current locale
const path = location.pathname;
let index = path.slice(1).indexOf('/');
if (index == -1) index = path.length;
const currentLocale = path.slice(1, index + 1);

function languageSwitchOnInit() {
    const dom = document.getElementById('lang-switch');
    if (dom) {
        dom.querySelector('.language-selected').addEventListener('click', () => {
            dom.classList.add('--open');
        });
        dom.querySelectorAll('.language-list div').forEach((domLang) => {
            if (domLang.classList.contains('--selected')) {
                domLang.addEventListener('click', () => dom.classList.remove('--open'));
            } else {
                domLang.addEventListener('click', () => {
                    const locale = domLang.getAttribute('data-lang');
                    location.href = location.href.replace(`/${currentLocale}`, `/${locale}`);
                });
            }
        });
    }
};

export {
    languageSwitchOnInit,
}
