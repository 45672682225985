import { HEADER_SETTINGS } from './_header';

let subMenus = [];

function subMenuOnInit() {
    document.querySelectorAll('.sub-menu').forEach((dom) => {
        let m = {
            dom: dom,
            innerDom: dom.querySelector('div'),
            items: [],
        };

        m.innerDom.querySelectorAll('ul li').forEach((domItem, index) => {
            const rel = domItem.getAttribute('data-rel');
            if (!rel) return;
            const domRel = document.querySelector(rel);
            if (!domRel) return;

            m.items.push({
                index: index,
                rel: rel,
                domRel: domRel,
                domItem: domItem,
            });

            domItem.addEventListener('click', () => window.scrollTo({
                top: domRel.offsetTop - m.innerDom.offsetHeight,
                behavior: 'smooth'
            }));
        });

        m.onScroll = function () {
            const self = this;

            // Mark correct item as active
            const offsetThreshold = self.innerDom.offsetHeight + 100;
            let active = 0;
            self.items.forEach((item, index) => {
                if (item.domRel.getBoundingClientRect().y < offsetThreshold) {
                    active = index;
                }
            });
            self.items.forEach((item, index) => {
                item.active = index == active;
                if (item.active) {
                    item.domItem.classList.add('--active');
                } else {
                    item.domItem.classList.remove('--active');
                }
            });

            // Skip next for non floating
            if (!self.dom.classList.contains('floating')) {
                return;
            }

            // Make it float
            const bounds = self.dom.getBoundingClientRect();
            let offset = HEADER_SETTINGS.size;
            if (self.dom.classList.contains('--offset')) {
                offset -= Math.ceil(self.innerDom.offsetHeight / 2) + 1;
            }
            if (bounds.y < offset) {
                self.dom.classList.add('--floating');
                self.dom.querySelector('div').style.top = offset + 'px';
                self.dom.style.minHeight = self.innerDom.offsetHeight + 'px';
            } else {
                self.dom.classList.remove('--floating');
            }
        };
        subMenus.push(m);
    });
};

function subMenuOnScroll() {
    subMenus.forEach((m) => m.onScroll());
};

export {
    subMenuOnInit,
    subMenuOnScroll,
}
