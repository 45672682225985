let pagination = {
    current: 0,
    count: 0,

    last: 0,
    lastCount: -1,
};

function setPaginationCallback(callback) {
    pagination.callback = callback;
}

function setPagination(current, count) {
    pagination.current = current;
    pagination.count = count;
    paginationOnUpdate();
}

function resetPagination() {
    pagination.current = 0;
    paginationOnUpdate();
}

function paginationGetCurrentPage() {
    return pagination.current;
}

function paginationOnInit() {
    paginationOnUpdate();
}

function getPageList() {
    const pageCount = pagination.count - 1;
    let pages = [];
    const pushAround = id => {
        for (let i = id - 2; i < id + 3; i++) {
            if (i < 0 || i > pageCount || pages.indexOf(i) > -1) {
                continue;
            }
            pages.push(i);
        }
    };
    pushAround(0);
    pushAround(pagination.current);
    pushAround(pageCount);
    return pages;
}

function paginationOnUpdate() {
    const dom = document.querySelector('.pages');
    if (dom && pagination.callback) {
        pagination.last = pagination.current;
        pagination.lastCount = pagination.count;

        dom.innerHTML = '';
        const list = getPageList();
        if (list.length <= 1) {
            return;
        }

        // Add go back
        (() => {
            const domPage = document.createElement('div');
            domPage.innerHTML = '<i class="fas fa-angle-left"></i>';
            domPage.className = `btn primary`;
            if (pagination.current == 0) {
                domPage.classList.add('--disabled');
            }
            domPage.addEventListener('click', () => {
                pagination.current--;
                pagination.callback();
            });
            dom.appendChild(domPage);
        })();

        // Add pages
        let last = 0;
        list.forEach((page) => {
            if (last + 1 != page && page != 0) {
                const domEllipsis = document.createElement('div');
                domEllipsis.className = 'btn secondary --disabled';
                domEllipsis.innerHTML = '<i class="fas fa-ellipsis-h"></i>';
                dom.appendChild(domEllipsis);
            }
            last = page;

            const domPage = document.createElement('div');
            domPage.innerText = page + 1;
            domPage.className = `btn ${page == pagination.current ? 'primary' : 'secondary'}`;
            domPage.addEventListener('click', () => {
                pagination.current = page;
                pagination.callback();
            });
            dom.appendChild(domPage);
        });

        // Add go forward
        (() => {
            const domPage = document.createElement('div');
            domPage.innerHTML = '<i class="fas fa-angle-right"></i>';
            domPage.className = `btn primary`;
            if (pagination.current >= pagination.count - 1) {
                domPage.classList.add('--disabled');
            }
            domPage.addEventListener('click', () => {
                pagination.current++;
                pagination.callback();
            });
            dom.appendChild(domPage);
        })();
    }
}

export {
    paginationOnInit,
    paginationGetCurrentPage,

    setPaginationCallback,
    setPagination,
    resetPagination,
}

// Make sure this is available on the window
window.setPagination = setPagination;
window.setPaginationCallback = setPaginationCallback;
window.getPagination = paginationGetCurrentPage;
