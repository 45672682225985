const ITEM_BOX_SWIPE_THREASHOLD = 120;

const itemBoxes = [];

function itemBoxOnInit() {
    document.querySelectorAll('.item-box').forEach((dom) => {
        const box = {
            dom: dom,
            paginationEnabled: dom.classList.contains('--pagination'),
            domElements: dom.querySelectorAll('.items li'),
            domDots: dom.querySelector('.dots ul') || [],
            current: 0,
            count: 0,
            setTab: function(tab){
                if (tab < 0 || tab >= this.count) {
                    return;
                }
                for (let i = 0; i < 100; i++) { this.dom.classList.remove(`offset-${i}`); };
                this.dom.classList.add(`offset-${tab}`);
                this.current = tab;
            },
        };
        itemBoxes.push(box);
    
        // Add events for Swiping
        if (!box.paginationEnabled) {
            return;
        }

        let xs, xe;
        dom.addEventListener('touchstart', (e) => {
            xs = e.touches[0].screenX;
            xe = xs;
        });
        dom.addEventListener('touchmove', (e) => {
            xe = e.touches[0].screenX;
            if (xs - xe > ITEM_BOX_SWIPE_THREASHOLD) {
                xs = xe;
                box.setTab(box.current + 1);
            } else if (xs - xe < -ITEM_BOX_SWIPE_THREASHOLD) {
                xs = xe;
                box.setTab(box.current - 1);
            }
        });
    });
}

function itemBoxOnResize(innerWidth) {
    let cnt = 1;
    if (innerWidth > 1300) {
        cnt = 5;
    } else if (innerWidth > 1100) {
        cnt = 4;
    } else if (innerWidth > 800) {
        cnt = 3;
    } else if (innerWidth > 300) {
        cnt = 2;
    }

    itemBoxes.forEach((box) => {
        const pageSize = cnt > box.domElements.length ? box.domElements.length : cnt;

        // Setup size & offset
        [1, 2, 3, 4, 5].forEach((s) => box.dom.classList.remove(`size-${s}`));
        for (let i = 0; i < 100; i++) { box.dom.classList.remove(`offset-${i}`); };
        box.dom.classList.add(`offset-0`);
        box.dom.classList.add(`size-${pageSize}`);
        box.current = 0;

        // Maintain aspect ratio
        setTimeout(() => {
            box.domElements.forEach((e) => {
                const div = e.querySelector('div');
                div.style.height = (Math.floor(div.getBoundingClientRect().width) - 20) + 'px';
            });
        }, 0);

        // Dots generation
        if (!box.paginationEnabled) {
            return;
        }

        let count = Math.ceil(box.domElements.length / pageSize);
        if (count == 1) { count = 0; } // Do not display dot when only one tab

        if (count == box.count) {
            return;
        }

        box.count = count;
        box.domDots.innerHTML = '';
        for (let i = 0; i < box.count; i++) ((tab) => {
            const domDot = document.createElement('li');
            domDot.addEventListener('click', () => {
                box.setTab(tab);
            });
            box.domDots.appendChild(domDot);
        })(i);
    });
};

export {
    itemBoxOnInit,
    itemBoxOnResize,
}
