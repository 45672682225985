import { HEADER_SETTINGS } from './_header';

let maps = [];

function stripDia(str) {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
}

function mapBoxOnInit() {
    const mapPoints = window.mapPoints || [];

    document.querySelectorAll('.mapbox').forEach((mapDom) => {
        mapboxgl.accessToken = 'pk.eyJ1Ijoidmxhc3RpbWlsc2VuZmVsZCIsImEiOiJjazdyenFjOTQwOWNoM2trNGlvNm9mc3IwIn0.bl9NfcvrFSAkDw2tKITZrA';
        const map = new mapboxgl.Map({
            container: mapDom.querySelector('.map'),
            style: 'mapbox://styles/vlastimilsenfeld/ck7s1itrq30gh1inj15pru3br',
            // center: [9.140555, 48.690833],
            center: [14.4378005, 49.0755381],
            zoom: 6
        });

        // Add to collection
        maps.push({
            dom: mapDom,
            map: map,
        });

        // Zoom in method
        const domScroller = mapDom.querySelector('.scroller');
        const zoomToActiveCollection = (points) => {
            let tl = {},
                rb = {};
            points.forEach((p, i) => {
                if (tl.longitude > p.longitude || i == 0) {
                    tl.longitude = p.longitude;
                }
                if (tl.latitude > p.latitude || i == 0) {
                    tl.latitude = p.latitude;
                }
                if (rb.longitude < p.longitude || i == 0) {
                    rb.longitude = p.longitude;
                }
                if (rb.latitude < p.latitude || i == 0) {
                    rb.latitude = p.latitude;
                }
            });
            const markerPadding = 50;
            let markerPaddingFromLeft = markerPadding;
            const domList = mapDom.querySelector('.list');
            if (domList) {
                markerPaddingFromLeft += domList.offsetLeft + domList.offsetWidth;
            }
            map.fitBounds([
                [tl.longitude, tl.latitude],
                [rb.longitude, rb.latitude]
            ], {
                padding: {
                    top: markerPadding,
                    bottom: markerPadding,
                    left: markerPaddingFromLeft,
                    right: markerPadding,
                }
            });
        };
        const domBoxSearch = mapDom.querySelector('.search');
        const domBoxFilters = mapDom.querySelector('.filters');
        const zoomToPoint = (id) => {
            mapPoints.forEach((p) => {
                if (p.id == id) {
                    let offset = 100;
                    try {
                        offset = domBoxSearch.getBoundingClientRect().height +
                        domBoxFilters.getBoundingClientRect().height;
                    } catch(e) {};

                    domScroller.scrollTo({
                        top: p.domItem.offsetTop - (offset + 50),
                        behavior: 'smooth'
                    });
                    map.flyTo({
                        center: [p.longitude, p.latitude],
                        zoom: 15,
                    });
                }
            });
        }

        // Go through all points & create popups
        mapPoints.forEach(function (p) {
            const domMarker = document.createElement('div');
            domMarker.className = 'marker';
            domMarker.addEventListener('click', () => p.activate());
            p.domMarker = domMarker;

            // Create mapbox marker
            new mapboxgl.Marker(domMarker)
                .setLngLat([p.longitude, p.latitude])
                .addTo(map);

            // Activation method
            p.activate = () => {
                zoomToPoint(p.id);

                mapPoints.forEach((pb) => {
                    if (pb.id == p.id) {
                        pb.domItem.classList.add('--active');
                        pb.domMarker.classList.add('--active');
                    } else {
                        pb.domItem.classList.remove('--active');
                        pb.domMarker.classList.remove('--active');
                    }
                });
            };
        });

        // Add events to all network items
        mapDom.querySelectorAll('._mapbox_item').forEach((dom) => {
            const id = dom.getAttribute('data-id');
            let point = null;

            mapPoints.forEach((mp) => {
                if (mp.id == id) {
                    point = mp;
                    mp.domItem = dom;
                }
            });

            dom.addEventListener('click', () => point.activate());
        });

        // Zoom into 1 point if only 1 is available
        if (mapPoints.length == 1) {
            map.on('load', () => mapPoints[0].activate());
        } else if (mapPoints.length > 1) {
            zoomToActiveCollection(mapPoints);
        }

        // Search filter
        const searchInput = mapDom.querySelector('.search input[type=text]');
        const domCountry = mapDom.querySelector('select');
        const domFilters = Array.prototype.slice.call(mapDom.querySelectorAll('.filters input[type=checkbox]'));
        let sto;

        function doSearch() {
            const phrase = stripDia(searchInput.value);
            let filters = ['noark', 'eshop', 'store', 'distributor', 'panelbuilder', 'reseller'];

            const country = (domCountry ? domCountry.value : '');

            if (domFilters.length > 0) {
                filters = domFilters.filter((f) => f.checked).map((f) => f.getAttribute('data-id'));
            }

            mapPoints.forEach((mp) => {
                mp.visible = (stripDia(mp.name).indexOf(phrase) > -1 ||
                    stripDia(mp.address).indexOf(phrase) > -1 ||
                    stripDia(mp.web).indexOf(phrase) > -1) &&
                    filters.indexOf(mp.type) > -1;

                if (country && mp.countryCode != country) {
                    mp.visible = false;
                }

                mp.domItem.style.display = mp.visible ? null : 'none';
                mp.domMarker.style.display = mp.visible ? null : 'none';
            });

            const visiblePoints = mapPoints.filter((mp) => mp.visible);
            if (visiblePoints.length == 1) {
                visiblePoints[0].activate();
            } else if (visiblePoints.length > 1) {
                zoomToActiveCollection(visiblePoints);
            }
        }
        searchInput.addEventListener('keydown', () => {
            clearTimeout(sto);
            sto = setTimeout(() => doSearch(), 250);
        });
        domFilters.forEach((f) => f.addEventListener('change', () => doSearch()));
        if (domCountry) {
            domCountry.addEventListener('change', () => doSearch());
        }
        if (domCountry) {
            const locale = HEADER_SETTINGS.locale.toUpperCase();
            for (let i = 0; i < domCountry.options.length; i++) {
                if (domCountry.options[i].value == locale) {
                    domCountry.value = locale;
                    doSearch();
                    break;
                }
            }
        }

        if (location.search != '') {
            location.search.slice(1).split('&').forEach((q) => {
                const parts = q.split('=');
                if (parts[0] == 'query') {
                    searchInput.value = decodeURIComponent(parts[1]);
                    doSearch();
                }
            });
        }
    });
}

function mapBoxOnResize() {
    let offset = HEADER_SETTINGS.size;

    maps.forEach((m) => {
        // m.dom.style.height = (window.innerHeight - offset) + 'px';
        m.map.resize();
    });
}

export {
    mapBoxOnInit,
    mapBoxOnResize,
}
