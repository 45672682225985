import { localize } from './_locale';

let HEADER_SETTINGS = {
    size: 50,
    locale: (() => {
        const path = location.pathname.slice(1);
        const index = path.indexOf('/');
        if (index == -1) {
            return path;
        }
        return path.slice(0, index || undefined);
    })() || 'en',
};

const HEADER_SEARCH_SECTIONS = {
    "product": 'generic.products',
    "category": 'generic.categories',
    "doc": 'generic.documents',
    "network": 'generic.network',
    "page": 'generic.pages',
};

function headerMenuLinksOnInit() {
    const domHeader = document.querySelector('header');
    if (!domHeader) {
        return;
    }

    const links = domHeader.querySelectorAll('.main .menu .links li');
    links.forEach((link) => {
        const a = link.querySelector('a');
        if (!a) return;
        a.addEventListener('click', () => {
            links.forEach((l) => l.classList.remove('active'));
            link.classList.add('active');
        });
    });

    // Link submenu activation
    let activatedTO;
    const mainLinks = domHeader.querySelectorAll('.links-main li');
    mainLinks.forEach((link) => {
        const submenu = link.querySelector('ul');
        if (!submenu) {
            return;
        }
        submenu.addEventListener('mouseover', () => {
            clearTimeout(activatedTO);
        });
        submenu.addEventListener('mouseover', () => {
            activatedTO = setTimeout(() => {
                mainLinks.forEach((l) => l.classList.remove('--activated'));
            }, 1000);
        });

        link.addEventListener('mouseover', () => {
            clearTimeout(activatedTO);
            mainLinks.forEach((l) => l.classList.remove('--activated'));
            link.classList.add('--activated');
        });
        link.addEventListener('mouseout', () => {
            activatedTO = setTimeout(() => {
                mainLinks.forEach((l) => l.classList.remove('--activated'));
            }, 1000);
        });
    });
}

function headerOnInit() {
    headerMenuLinksOnInit();

    const domACQ = document.getElementById('autocomplete_query');
    const domACR = document.getElementById('autocomplete_results');

    if (!domACQ || !domACR) {
        return;
    }

    let autocompleteTO;


    function autocomplete() {
        if (domACQ.value.trim() == '') {
            domACR.classList.remove('--active');
            return;
        }

        fetch(`/${HEADER_SETTINGS.locale}/autocomplete?query=${encodeURI(domACQ.value)}`)
            .then((res) => res.json())
            .then(data => {
                let html = '';
                if (data.results) {
                    html += '<ul>';
                    data.results.forEach((sr) => {
                        html += `<li>`;
                        html += `<span class="type">${localize(HEADER_SEARCH_SECTIONS[sr.type])}</span>`
                        html += `<a href="${sr.url}">${sr.name}</a>`;
                        html += `</li>`;
                    });
                    html += '</ul>';
                } else {
                    html = localize('search.noResults');
                }
                // const html = HEADER_SEARCH_SECTIONS.map((t) => {
                //     let html = '';
                //     if (data[t.id].length) {
                //         html += `<h6>${localize(t.label)}</h6>`;
                //         html += '<ul>';
                //         data[t.id].forEach((p) => {
                //             html += `<li><a href="${p.url}">${p.name}</a></li>`;
                //         });
                //         html += '</ul>';
                //     }
                //     return html;
                // }).join('');

                domACR.innerHTML = html;
                domACR.classList.add('--active');
            });
    }

    domACQ.addEventListener('keyup', (e) => {
        if (e.which == 13) {
            location.href = `/${HEADER_SETTINGS.locale}/search?query=${encodeURI(domACQ.value)}`;
        } else {
            clearTimeout(autocompleteTO);
            autocompleteTO = setTimeout(() => autocomplete(), 500);
        }
    });

    // Auto trigger
    if (domACQ.value != "") {
        autocomplete();
    }

    // Hamburger
    const domHeader = document.querySelector('header');
    const domHamburger = document.querySelector('.hamburger');
    domHamburger.addEventListener('click', () => {
        domHeader.classList.toggle('--active');
    });
}

function headerOnScroll() {
    const dom = document.getElementsByTagName('header')[0];
    if (window.scrollY > 50) {
        dom.classList.add('small');
    } else {
        dom.classList.remove('small');
    }
    const domPlaceholder = document.querySelector('.header-placeholder');
    if (domPlaceholder) {
        domPlaceholder.style.height = dom.offsetHeight + 'px';
    }
    HEADER_SETTINGS.size = dom.offsetHeight;
}

function headerOnResize() {
    const dom = document.getElementsByTagName('header')[0];
    const domPlaceholder = document.querySelector('.header-placeholder');
    if (domPlaceholder) {
        domPlaceholder.style.height = dom.offsetHeight + 'px';
    }
    HEADER_SETTINGS.size = dom.offsetHeight;
}

export {
    HEADER_SETTINGS,

    headerOnInit,
    headerOnScroll,
    headerOnResize,
};
