const NOARK_COOKIE_NAME = 'noarkCookieAccepted';

function footerOnInit() {
    const banner = document.getElementById('cookie-banner');
    if (!banner || document.cookie.indexOf(`${NOARK_COOKIE_NAME}=`) > -1) {
        return;
    }

    banner.style.display = 'block';

    banner.querySelector('.close').addEventListener('click', () => {
        let date = new Date();
        date.setTime(date.getTime() + (90 * 24 * 60 * 60 * 1000));
        document.cookie = `${NOARK_COOKIE_NAME}=true; expires=${date.toUTCString()}; path=/; SameSite=Strict`;
        banner.style.display = 'none';
    });
}

export {
    footerOnInit,
}
