let buttons = [];

function scrollUpButtonOnInit() {
    document.querySelectorAll('.scroll-up-btn').forEach((dom) => {
        buttons.push({
            dom: dom,
        });
        dom.addEventListener('click', () => window.scrollTo({
            top: 0,
            behavior: 'smooth'
        }));
    });
}

function scrollUpButtonOnScroll() {
    if (window.scrollY > 500) {
        buttons.forEach((btn) => btn.dom.classList.remove('--hidden'));
    } else {
        buttons.forEach((btn) => btn.dom.classList.add('--hidden'));
    }
}

export {
    scrollUpButtonOnInit,
    scrollUpButtonOnScroll,
}
