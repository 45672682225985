let locales = {};

function setLocaleKey(key, value) {
    locales[key] = value;
}

function localize(key) {
    return locales[key] || key;
}

export {
    setLocaleKey,
    localize,
}

// Make sure this is available on the window
window.setLocaleKey = setLocaleKey;
