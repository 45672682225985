function popupBoxOnInit() {
    document.querySelectorAll('.popup-boxes').forEach((dom) => {
        // Selecting popups
        let active = 0;
        const frames = dom.querySelectorAll('.elements .element');
        const elements = dom.querySelectorAll('.popup .element');
        const count = elements.length;
        function go(goLeft) {
            elements[active].classList.remove('--active');
            if (goLeft) {
                active--;
            } else {
                active++;
            }
            if (active < 0) {
                active = count - 1;
            } else if (active >= count) {
                active = 0;
            }
            elements[active].classList.add('--active');
            history.pushState({}, null, '#' + active);
        }

        function close() {
            history.pushState({}, null, '#');
            dom.classList.remove('--open');
        }

        function open(index) {
            history.pushState({}, null, '#' + index);
            elements[active].classList.remove('--active');
            active = index;
            elements[active].classList.add('--active');
            dom.classList.add('--open');
        }

        frames.forEach((el, index) => el.addEventListener('click', () => open(index)));
        dom.querySelector('.btn-left').addEventListener('click', () => go(true));
        dom.querySelector('.btn-right').addEventListener('click', () => go(false));
        dom.querySelector('.btn-close').addEventListener('click', () => close());
        dom.querySelector('.popup-bg').addEventListener('click', () => close());

        // Activate the frame from hash
        if (location.hash.length > 1) {
            const id = Number(location.hash.slice(1));
            open(id);
        }

        // Filtering
        const filters = Array.prototype.slice.call(dom.querySelectorAll('input[type=checkbox]'));
        function filterElements() {
            const ids = filters.filter((f) => f.checked).map((f) => f.getAttribute('data-id'));
            frames.forEach((frame) => {
                if (ids.indexOf(frame.getAttribute('data-id')) > -1) {
                    frame.style.display = null;
                } else {
                    frame.style.display = 'none';
                }
            });
        }
        filterElements();
        filters.forEach((filter) => filter.addEventListener('change', () => filterElements()));

        // Selecting images
        elements.forEach((domEl) => {
            let selected = 0;

            const image = domEl.querySelector('.image');
            const thumbnails = domEl.querySelectorAll('.images li');

            thumbnails.forEach((thumbnail, index) => thumbnail.addEventListener('click', () => {
                thumbnails[selected].classList.remove('--active');
                selected = index;
                thumbnails[selected].classList.add('--active');
                image.style.backgroundImage = `url(${thumbnail.getAttribute('data-url')})`;
            }));
        });
    });
};

export {
    popupBoxOnInit,
}
