import './main.scss'; // Styles

// Imports of loaders etc.
import { headerOnInit, headerOnScroll, headerOnResize } from './components/_header';
import { bigSearchOnInit } from './components/_bigsearch';
import { heroBoxOnInit } from './components/_herobox';
import { itemBoxOnInit, itemBoxOnResize } from './components/_itembox';
import { filterBoxOnInit } from './components/_filterbox';
import { languageSwitchOnInit } from './components/_languageswitch';
import { subMenuOnInit, subMenuOnScroll } from './components/_submenu';
import { mapBoxOnInit, mapBoxOnResize } from './components/_mapbox';
import { popupBoxOnInit } from './components/_popup_box';
import { formOnInit } from './components/_form';
import { footerOnInit } from './components/_footer';
import { scrollUpButtonOnInit, scrollUpButtonOnScroll } from './components/_scrollupbtn';
import { paginationOnInit } from './components/_pagination';

// On Hook Links overrides default browser actions on those links with dynamic reload
function onHookLinks() {
    document.querySelectorAll('a').forEach((link) => {
        link.addEventListener('click', (e) => {
            const href = link.getAttribute('href');

            // Skip empty & tel:,mailto:...
            if (href == '' || href.indexOf(':') > -1) {
                return;
            }

            e.preventDefault();
            e.stopPropagation();

            onReload(href);
        });
    });
}

// On Reload
function onReload(url) {
    window.history.pushState(url,'Tada!',url);
    fetch(url)
        .then((res) => res.text())
        .then((html) => {
            const doc = document.createElement('html');
            doc.innerHTML = html;
            document.body.innerHTML = doc.querySelector('body').innerHTML;
            onInit();
        });
}

// On Init
function onInit() {
    itemBoxOnInit();
    headerOnInit();
    bigSearchOnInit();
    heroBoxOnInit();
    filterBoxOnInit();
    languageSwitchOnInit();
    subMenuOnInit();
    mapBoxOnInit();
    popupBoxOnInit();
    formOnInit();
    footerOnInit();
    scrollUpButtonOnInit();
    paginationOnInit();
    // onHookLinks();
}

// On Scroll
function onScroll() {
    headerOnScroll();
    subMenuOnScroll();
    scrollUpButtonOnScroll();
}

// On Resize
function onResize() {
    headerOnResize();
    mapBoxOnResize();
    itemBoxOnResize(window.innerWidth);
}

// Kick things off
window.addEventListener('load', () => {
    onInit();
    onResize();
    onScroll();
});
window.addEventListener('scroll', onScroll);
window.addEventListener('resize', onResize);
// window.addEventListener('popstate', (s) => onReload(s.state));
