const HERO_SWITCH_INTERVAL = 5000;
const HERO_SWIPE_THREASHOLD = 120;

function heroBoxOnInit() {
    document.querySelectorAll('.hero-box').forEach((dom) => {
        let heroTab = 0,
            heroTabLen = dom.querySelectorAll('.items li').length,
            heroTimeout;

        const switchTab = (tab) => {
            heroTab = tab;
            if (heroTab < 0) {
                heroTab = heroTabLen - 1;
            } else if (heroTab >= heroTabLen) {
                heroTab = 0;
            }
            
            for (let i = 0; i < 100; i++) { dom.classList.remove(`offset-${i}`); };
            dom.classList.add(`offset-${heroTab}`);

            clearTimeout(heroTimeout);
            heroTimeout = setTimeout(() => {
                heroTab++;
                if (heroTab >= heroTabLen) {
                    heroTab = 0;
                }
                switchTab(heroTab);
            }, HERO_SWITCH_INTERVAL);
        };
        switchTab(0);

        dom.querySelectorAll('div.dots li').forEach((domButton, id) => {
            domButton.addEventListener('click', () => switchTab(id));
        });

        // Swipe
        let xs, xe;
        dom.addEventListener('touchstart', (e) => {
            xs = e.touches[0].screenX;
            xe = xs;
        });
        dom.addEventListener('touchmove', (e) => {
            xe = e.touches[0].screenX;
            if (xs - xe > HERO_SWIPE_THREASHOLD) {
                xs = xe;
                switchTab(heroTab + 1);
            } else if (xs - xe < -HERO_SWIPE_THREASHOLD) {
                xs = xe;
                switchTab(heroTab - 1);
            }
        });
    });
};

export {
    HERO_SWITCH_INTERVAL,

    heroBoxOnInit,
};
